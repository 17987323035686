import request from '@/utils/request'
//获取配置  （推荐） （会员信息 ）
export function getconfig(params = {}) {
	const queryParams = new URLSearchParams(params).toString();
	const url = `/other/getconfig?${queryParams}`;
	return request({
		url,
		method: 'get'
	});
}

//获取協議 隱私協議 用戶協議
export function getQueryprotocol(params = {}) {
	const queryParams = new URLSearchParams(params).toString();
	const url = `/other/queryprotocol?${queryParams}`;
	return request({
		url,
		method: 'get'
	});
}

//提交反餽內容

export function Addfeedback(data) {
    return request({
      url: '/other/addfeedback',
      method: 'post',
      data: JSON.stringify(data)
    })
  }