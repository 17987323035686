
import axios from 'axios';
import {
  ElLoading,
  ElMessageBox
} from 'element-plus'; //把插件里的loding引出来


//process.env.NODE_ENV   
const service = axios.create({
  // baseURL: 'https://apiais.yinglee-underwearapp.com/api/app',
  baseURL: 'https://api.a-cut.com.cn/api/app',
  // baseURL: 'http://192.168.1.50:106/api/app',
  timeout: 100000, // 请求超时时间  
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
});


// 请求拦截器  
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么  
    // 例如，设置认证令牌  
    if (localStorage.getItem('token')) {
      config.headers.tokens = `${localStorage.getItem('token')}`;
      // config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }

    // 显示加载动画

    // let loadinglnstance = ElLoading.service({
    //   text: "拼命加载中"
    // }); //打开loading

    // loadinglnstance.close(); //关闭loading

    // 如果需要，可以在这里修改请求数据  
    // config.data = transformRequestData(config.data);  

    // 将配置返回给下一个拦截器  
    return config;
  },
  error => {
    // 对请求错误做些什么  
    // 这里通常是对请求发起之前发生的错误进行处理  
    // 比如请求配置错误等  
    console.error('请求拦截器发生错误：', error);
    ElMessageBox.alert(error.message, "提示", {
      type: "error",
      showClose: false
    });
    // 对错误做点什么，比如显示错误通知  
    // showErrorNotification(error.message);  
    // 返回一个Promise.reject()  
    return Promise.reject(error);
  }
);

// 响应拦截器  
service.interceptors.response.use(
  response => {
    // 对响应数据做点什么，例如判断状态码  
    const res = response.data;
    const headers = response.headers;
    let token = null;
    if (headers['authorization']) {
      token = headers['authorization'];
    } else if (headers['tokens']) {
      token = headers['tokens'];
    }
    if (token) {
      token = headers['tokens'];
      localStorage.setItem('token', token);
    }
    if (res.code !== 200) {
      // 根据业务需求处理错误码  
      console.error('错误码：', res, res.code, '错误信息：', res.message);
      ElMessageBox.alert(res.msg, "提示", {
        type: "error",
        showClose: false,
        confirmButtonText: '确定',
        callback: action => {
          if (action === 'confirm') {
            // 用户点击了确定  
            //404
          } else if (action === 'cancel') {
            // 用户点击了取消（如果对话框有取消按钮的话）  
          }
        }
      });
      return Promise.reject(new Error(res.msg || 'Error'));
    } else {
      return res;
    }
  },
  error => {
    // 对响应错误做点什么  
    console.error('响应出错：', error); // for debug  
    if (error.response) {  
      // 服务器返回了错误的HTTP状态码  
      // 你可以访问 error.response.status, error.response.data 等  
      console.error('服务器错误：', error.response.status, error.response.data);  
    } else if (error.request) {  
      // 请求已经发起，但没有收到响应  
      // ElMessageBox.alert(error.request.message, "提示", {
      //   type: "error",
      //   showClose: false,
      //   confirmButtonText: '确定',
      //   callback: action => {
      //     if (action === 'confirm') {
      //       // 用户点击了确定  
      //       //404
      //     } else if (action === 'cancel') {
      //       // 用户点击了取消（如果对话框有取消按钮的话）  
      //     }
      //   }
      // });
      console.error('请求已发送，但没有收到响应：', error.request);  
    } else {  
      // 设置请求时触发的错误  
      console.error('请求配置错误：', error.message);  
    }  
    return Promise.reject(error);
  }
);

export default service;