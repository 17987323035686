export default {
    namespaced: true,
    state: () => ({
      id:'0',//0新增
      textareaModel: '',//图文内容
      tabName: '',
      bgImg: '',//背景图
      imageData: '', //数字人
      imageDataId: 0, //數字人id
      decorateObj: {//装饰
        img: '',
        state: false,
      },
      wordObj: { //文字
        add: false,
        title: '',
        fontSize: 16, //文字大小
        background: "", //背景颜色
        fcolor: "#333333",
        opacity: 100,
        letterSpacing: 0, //文字间歇 （目前接口没做，先写上去
        fontfamily: '', //字体id
        fontfamilyurl: '', //字体url
        fontfamilyTitle: '', //字体名字
        transform: '', //文字旋转角度（目前接口没做，先写上去
        textalign: 'center',
        defaultFont: {
          fontfamily: '', //字体id
          fontfamilyurl: '', //字体url
          fontfamilyTitle: '', //字体名字
          textalign: 'center'
        }
      },
      wordStyleIsShow: false, //文字样式显示
      IsTxtShow: false,
      music: {  //背景音乐
        musicId: 0, //音乐id
        musicsound: 50, //音乐大小
        title:''
      },
      soundData: {
        soundDataId:'',
        name:''
      },//聲音id
      volObj: {
        volume: 50, //音量
        speechrate: 0, //语速
        pitchrate: 0, //语调
      },
      //容器寬高
      containerWH: {
        width: 0,
        height: 0,
      },
      //数字人
      vidimageDataObj: {
        vidimageData: '',
        vidData: ''
      },
  
    }),
    mutations: {
      setID(state, params) {
        state.id = params;
      },
  
      setTabName(state, params) {
        state.tabName = params;
      },
      setBgImageData(state, params) {
        state.bgImg = params;
      },
      setImageData(state, params) {
        state.imageData = params;
      },
      setImageDataId(state, params) {
        state.imageDataId = params;
      },
      setDecorate(state, params) {
        state.decorateObj = { ...state.decorateObj, ...params }

      },
      setIsTxtShow(state, params) {
        state.IsTxtShow = params;
      },
      setwordStyleIsShow(state, params) {
        state.wordStyleIsShow = params;
      },
      setword(state, params) {
        state.wordObj = { ...state.wordObj, ...params };

      },
      //背景音乐
      setmusic(state, params) {
        state.music = { ...state.music, ...params };
      },
      //声音id
      setVidData(state, params) {
        // state.soundDataId = params;
        state.soundData = { ...state.soundData, ...params };
      },
      //声音音量
      setVolObjData(state, params) {
        state.volObj = { ...params };
      },
      //图文内容
      TextareContentData(state, params) {
        state.textareaModel = params;
      },
      setContainerWH(state, params) {

        state.containerWH = { ...state.containerWH, ...params };
      },
  
  
  
      //数字人
      setVidimageData(state, params) {
        state.vidimageDataObj = { ...params };
      },
  
    },
    actions: {
      
      updateID({ commit }, params) {
        commit('setID', params);
      },
      updateTabName({ commit }, params) {

        commit('setTabName', params);
      },
      updateBgImageData({ commit }, params) {

        commit('setBgImageData', params);
      },
      updateImageData({ commit }, params) {

        commit('setImageData', params);
      },
      updateImageDataId({ commit }, params) { //數字ID
        commit('setImageDataId', params);
      },
      updateDecorate({ commit }, params) {

        commit('setDecorate', params);
      },
      updateIsTxtShow({ commit }, params) {
        commit('setIsTxtShow', params);
      },
      updateWordStyleIsShow({ commit }, params) {
        commit('setwordStyleIsShow', params);

      },
      updateWord({ commit }, params) {
        commit('setword', params);
      },
      updateMusic({ commit }, params) {
        commit('setmusic', params);

      },
      updateVidData({ commit }, params) {  //声音id
        commit('setVidData', params);
      },
      updateVolObjData({ commit }, params) {
        commit('setVolObjData', params);
      },
      updateTextareContentData({ commit }, params) { //图文内容
        commit('TextareContentData', params);
      },
      updateContainerWH({ commit }, params) { //容器寬高
        commit('setContainerWH', params);
      },
  
  
  
  
  
  
  
      //生成数字人数据
      updateVidPicData({ commit }, params) {
        commit('setVidimageData', params);
      },
  
  
  
    },
    getters: {
      id: (state) => state.id,
      tabName: (state) => state.tabName,
      bgImg: (state) => state.bgImg,
      imageData: (state) => state.imageData,
      decorateObj: (state) => state.decorateObj,
      wordObj: (state) => state.wordObj,
      IsTxtShow: (state) => state.IsTxtShow,
      wordStyleIsShow: (state) => state.wordStyleIsShow,
      music: (state) => state.music,
      soundData: (state) => state.soundData,
      textareaModel: (state) => state.textareaModel,
      volObj: (state) => state.volObj,
      containerWH: (state) => state.containerWH,
      imageDataId: (state) => state.imageDataId,
  
  
      vidimageDataObj: (state) => state.vidimageDataObj,
  
    },
  };