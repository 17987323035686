import { userInfo } from "@/api/login";
import { getconfig } from "@/api/configBuy";
import { checkVipExpiry } from "@/api/packFun";
export default {
    namespaced: true,
    state: {

        user: null,
        userToken: null,
        isLoginShow: false, //是否展示login頁面
        generalization: '', //推广信息
        vipInfo: null, //会员信息
        maxTextareaLength: '300',//字数
        vipExpir: {
            txtTip: '',
            isExpire: false
        },
        routerPath:''
    },
    mutations: {
        SET_ROUTERPATH(state, params) { //會員信息
            state.routerPath =params;
        },
        SET_VIPEXPIR(state, params) { //會員信息
            state.vipExpir = { ...state.vipExpir, ...params };
        },
        SET_VIPINFO(state, params) { //會員信息
            state.vipInfo = params;
        },
        SET_GENERALIZATION(state, params) { //推广
            state.generalization = params;
        },
        SET_MAXTEXTAREALENGTH(state, params) { //字数
            if (params == 0) {
                state.maxTextareaLength = '300';
            } else {
                let find = state.vipInfo.find((data) => data.id == params);
                state.maxTextareaLength = find.text;
            }

        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_USER_TOKEN(state, token) {
            state.userToken = token;
        },
        SET_LOGIN_SHOW(state, show) {
            // console.log(show, 'mutations')
            state.isLoginShow = show;
        },

    },
    actions: {
        UPDATEROUTERPATH({ commit }, params) {
            commit('SET_ROUTERPATH', params);
          },
        
        async fetchUserInfo({ commit, state }) {
            try {
                let res = await getconfig(); // 注意：这里应该是一个实际存在的异步函数  

                if (res.code === 200 && res.conten) {
                    commit('SET_GENERALIZATION', res.conten[0].generalization);
                    commit('SET_VIPINFO', res.conten[0].vip);
                    localStorage.setItem("generalization", JSON.stringify(res.conten[0].generalization));
                    localStorage.setItem("vipInfo", JSON.stringify(res.conten[0].vip));
                }

                const token = localStorage.getItem("token");
                if (token) {
                    commit('SET_USER_TOKEN', token);
                    // 假设userInfo是一个返回Promise的函数  
                    let response = await userInfo(); // 注意：这里应该是一个实际存在的异步函数  
 
                    if (response.code === 200 && response.conten) {
                        let user = response.conten[0]
                        commit('SET_USER', user);
                        localStorage.setItem("user", JSON.stringify(user));
      
                        if (user) {
                            let { txtTip, isExpire } = await checkVipExpiry(user.viptimes);
                            if (isExpire || response.conten[0].viptype == 0) { //過期或普通用戶
                                commit('SET_MAXTEXTAREALENGTH', '0');
                  
                            } else {
                                commit('SET_MAXTEXTAREALENGTH', user.viptype);
                            }
                            commit('SET_VIPEXPIR', {
                                txtTip: txtTip,
                                isExpire: isExpire
                            });
                        } else {
                            commit('SET_MAXTEXTAREALENGTH', '0');
                        }

                    }
                } else {
                    commit('SET_USER_TOKEN', null);
                }
            } catch (e) {
                console.error("获取用户信息失败:", e);
            }
        },

    },
    getters: {
        vipExpir: state => state.vipExpir,
        isLoginShow: state => state.isLoginShow,
        user: state => state.user,
        userToken: state => state.userToken,
        vipInfo: state => state.vipInfo,
        generalization: state => state.generalization,
        maxTextareaLength: state => state.maxTextareaLength,
        routerPath:state => state.routerPath,

    },
};