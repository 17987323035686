<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

html,
body {
  font-family: "PingFang SC", "Helvetica Neue", "Helvetica", "Arial";
  background: #161823;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  box-sizing: border-box;
  overflow-y: hidden; 
}
</style>
